:root {
  /* --color-highlight: red; */
  /* Purple */
  --color-one: #2a2438;
  --color-two: #352f44;
  --color-three: #5c5470;
  --color-four: #dbd8e3;
  --text-color:#dbd8e3;

  /* GrayScale */
  /* --color-one: #1e2022;
  --color-two: #52616b;
  --color-three: #c9d6df;
  --color-four: #f0f5f9;
  --text-color:#f0f5f9; */

  /* Gray&Dark */
  /* --color-one: #222831;
  --color-two: #2a323d;
  --color-three: #191d24;
  --color-four: #eeeeee;
  --text-color: #eeeeee; */

  /* Light */
  --color-one: #f4f5f8;
  --color-two: #c8cfda;
  --color-three: #9ba4b4;
  --color-four: #373944;
  --text-color:#373944;


  /* --color-highlight: var(--color-three) */
  --color-highlight: rgb(112, 127, 146);



  /* --color-four: #2c90a1;
  --color-three: #82e95a;
  --color-two: #3a1111;
  --color-one: #0f1801;
  --text-color: var(--color-four); */
}



.App {
  text-align: center;
  background-color:black;
  height: 100vh;
}

.heading {
    /* font-size: 50px; */
    font-size: 40px;
    padding-left: 10vw;
    padding-top: 20px;
    padding-bottom: 30px;
    font-weight: lighter;
}

h2 {
    font-weight: lighter;
}


p {
    font-weight: normal;
    /* text-align: justify; */
    text-align: left;
}

a {
  color: var(--color-three);
  text-decoration: none;
}

li {
  color: var(--text-color);
  padding: 3px;
  /* color: red; */
}
