.profileContainer {
    display: flex;
    flex-direction: column;
    text-align: left;
}


.profile {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    padding: 20px 0 30px 0;
}

.profile > div {
    margin: 30px;
 }

.imgContainer{
    flex: 2;
}

.imgContainer > img {
    border: 1px solid var(--color-one);
    /* border: 3px solid var(--text-color); */
    border-radius: 5px;
}

.profileText {
    display: flex;
    flex-direction: column;
    /* text-align: justify; */
    width: 20px;
    flex: 4;
    min-width: 200px;
}