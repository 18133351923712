/* WHY */
.whyContainer {
    padding-top: 10px;
    padding-bottom: 30px;
}

/* COMPETENCIES */
.competenciesContainer {
    padding-top: 10px;
    padding-bottom: 30px;
}

.competencies {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-around;
    /* color: red; */
}


/* REFRENCES */
.referencesContainer {
    display: flex;
    flex-direction: column;
    padding-top: 10px;
    padding-bottom: 30px;
    /* background-color: white; */
}

.references {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    background-color: var(--color-one);
    border-radius: 5px;

}

.referencesImgContainer > img {
    margin: 30px;
    height: 40px;


    /* border: 10px solid black; */
    border-radius: 5px;
}