.awards {
    display: flex;
    flex-direction: column;
    /* flex-wrap: wrap; */
    font-size: 1.2em;
    padding-bottom: 20px;
}

.awards > div {
    padding: 20px;
    color : var(--color-one);
    font-size: 1.2em;
}