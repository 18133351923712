.coachingContainer {
    padding-top: 10px;
    padding-bottom: 30px;
}

.coaching {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
}

.coaching > div {
    /* background-color: var(--color-two);
    border-radius: 10px;
    padding: 10px; */
    /* flex-basis: 200px; */
    /* flex-basis: calc(25%-20px); */
}

.areas {
    flex: 2;
}

.tailor {
    flex: 3;
    margin-left:20px;
    min-width: 300px;
}

.individual {
    margin-left: 20px;
    min-width: 300px;
    flex: 3;
}