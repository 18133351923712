.footer {
    display: flex;
    flex-wrap: wrap;
    justify-content:space-between;
    /* grid-template-columns: auto auto; */
    /* grid-template-columns: repeat(auto-fit, minmax(100px, 1fr)); */
    /* background-color: blue; */
    /* height: 10vh; */
    width: 100%;
    /* color: red; */
}

.footer > div {
    margin-right: 30px;
    /* height: 50px; */
    /* width: 300px; */
    /* border: 2px solid black; */
}

.footerContainer {
    display: flex;
    flex-direction: column;
    text-align: left;

    padding: 20px 20px 20px 0;
    /* background-color: yellow; */
}