.Header {
    min-height: 10vh;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px 0 20px;
}

.HeaderTitle {
    /* background-color: black; */
    min-width: 200px;
    font-size: 1.1em;
    /* background-color: green; */
}

.HeaderTitle:hover {
    cursor: pointer;
    /* color: var(--color-highlight); */
}

.HeaderTitleName {
    font-size: 1.34em;
    color: var(--color-four);
}
.HeaderTitleCompany {
    font-size: 1.0em;
    color: var(--color-three);
}

.desktopMenu {
    display: flex;
    flex-direction: row;
    /* width: 100%; */
    justify-content: right;
    /* background-color: yellow; */
}

.desktopMenu> div {
    /* background-color: blue; */
    font-size: 1.2em;
    padding: 0 40px 0 40px;
}

.mobileMenu {
    font-size: 2.0em;
    padding-right: 20px;
}

.mobileDropdown {
    position: fixed;
    right: 20px;
    display: flex;
    flex-direction: column;
    text-align: right;
    background-color: var(--color-one);
    padding: 10px;
    border-radius: 0 0 5px 5px; 
    font-size: 0.8em;

    animation-name: SlideDownMobileMenu;
    animation-duration: 0.4s;
    transition-timing-function: linear;
}

.menuOption:hover {
    color: var(--color-highlight);
    cursor: pointer;
    /* background-color: blue; */
}

.menuOptionSelected {
    /* background-color: white; */
    color: var(--color-three);
    /* background-color: orange; */
}

@keyframes SlideDownMobileMenu {
    0% {
        max-height: 0px;
        overflow: hidden;
    }
    100% {
        max-height:200px;
        overflow: hidden;
    }
}