.resumeContainer{
    display: flex;
    flex-direction: column;
    padding: 20px 0 50px 0;
}

.resume {
    margin-top: 30px;
    padding: 20px 0 20px 0;
    display: inline-grid;
    grid-template-columns: 105px 150px auto;
    grid-template-rows: auto;
    grid-auto-flow: row;
    /* height: 100vh; */

    /* grid-template-columns: 100px 100px auto; */
    /* background-color: var(--color-one);
    border: 1px solid var(--color-three); */
    /* background-color: gray; */
    border-radius: 5px;
}

.resumeTextMobile {
    display: none;
    margin-left: 20px;
    margin-right: 10px;
    margin-bottom: 50px;
    margin: 0px;

    animation-name: SlideDownResumeTextMobile;
    animation-duration: 1.5s;
    transition-timing-function: linear;
}

.resumeText {
    display: flex;
    flex-direction: column;

    /* animation-name: FadeIn; */
}
.resumeTextSlideDown {
    animation-name: SlideDownResumeText;
    animation-duration: 1.5s;
    transition-timing-function: linear;
}


.resumeTextPreview {
    max-height: 2.4em;
    overflow: hidden;
}


.resumeTextPreview:hover {
    color: var(--color-highlight);
    cursor: pointer;
}

/* .resumeDate { color: var(--color-three); }
.resumeTitle { color: var(--color-three); } */
.resumeDropdownSymbol { color: var(--color-three); }
.resumeDropdownSymbol:hover {
    color: var(--color-highlight);
    cursor: pointer;
}

.resume > div {
    /* border: 2px solid black; */
    padding: 10px 10px 10px 10px;
    /* max-height: 100px; */
    overflow: hidden;
    text-overflow: ellipsis;
}

@keyframes SlideDownResumeText {
    0% {
        max-height: 2.4em;
        overflow: hidden;
    }
    100% {
        max-height:800px;
        overflow: hidden;
    }
}

@keyframes SlideDownResumeTextMobile {
    0% {
        max-height: 0px;
        overflow: hidden;
    }
    100% {
        max-height:800px;
        overflow: hidden;
    }
}