.Layout {
    /* background-color: white; */
    /* display: flex;
    flex-direction: column; */
    background-color: var(--color-one);
    color: var(--text-color);
    height: 100%;
    /* justify-content: space-between; */
}

.SectionOuter {
    display: flex;
    min-height: 20vh;
    justify-content: center;

}

.SectionInner {
    max-width: 800px;
    width: 80vw;
    display: flex;
    justify-content: center;
    text-align: left;
    /* padding: 20px 0 0 0; */
    flex-direction: column;
    /* background-color: pink; */
    /* color: red; */
}

/* .SectionOuter:first-child {
    color: red;
} */

.SectionOuter ~ * > .SectionInner{
    padding-top: 8vh;
    padding-bottom: 15vh;
    /* border: 29px black solid;
    color: blue; */
}

.SectionOuter:last-child .SectionInner{
    padding-top: 0px;
    padding-bottom: 0px;
}

.SectionOuter:nth-child(2n+1) {
    background-color: var(--color-two);
}

.SectionOuter:nth-child(2n) {
    background-color: var(--color-three);
}

/* Header */
.SectionOuter:nth-child(1) {
    /* min-height: 10vh; */
    min-height: 90px;
    /* max-height: 10vh; */
    /* min-height: */
    background-color: var(--color-one);
    /* background-color: pink; */
    position: fixed;
    width: 100%;
    opacity: 0.95;
    /* box-shadow: 5px 3px 5px var(--color-four); */
}

.SectionOuter:nth-child(2) {
    padding-top: 10vh;
}

/* Footer */
.SectionOuter:last-child {
    min-height:10vh;

    /* height: 100%; */
    /* background-color: white; */
    background-color: var(--color-one);
    background-color: var(--text-color);
    background-color: blueviolet;
    /* background-color: blue; */
    color: var(--color-two);
    /* background-color: pink; */
}